<template>
    <div class="add-news-form">
        <div class="form-item">
            <div class="hint-w">
                <span class="hint require">文章分類:</span>
            </div>
            <a-dropdown :trigger="['click']" @visibleChange="onCategoryVisible">
                <div class="select category" :data-value="mCategory.codeid" v-text="mCategory.codedesc || '請選擇分類'"></div>
                <a-menu slot="overlay">
                    <a-menu-item key="-1" v-if="loadingCategory">
                        <div class="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </a-menu-item>
                    <a-menu-item @click="onCategoryItemClick({codeid: '', codedesc: ''})" v-if="!loadingCategory">
                        <span>請選擇</span>
                    </a-menu-item>
                    <a-menu-divider v-if="categorys.length"/>
                    <a-menu-item v-for="(category, index) in categorys" :key="index" @click="onCategoryItemClick(category)">
                        <span v-text="category.codedesc"></span>
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
        <div class="form-item">
            <div class="hint-w">
                <span class="hint require">文章標題:</span>
            </div>
            <input type="text" class="title" placeholder="請編輯文章標題" v-model="formData.title"/>
        </div>
        <div class="form-item">
            <div class="hint-w">
                <span class="hint">文章摘要:</span>
            </div>
            <input type="text" class="second-title" placeholder="請輸入文章摘要、導讀文本內容" v-model="formData.introduce"/>
        </div>
        <div class="form-item">
            <div class="hint-w">
                <span class="hint require">是否原創:</span>
            </div>
            <span :class="{'checkbox': true, 'checked': formData.isoriginal == true}" @click="setFormData('isoriginal', true)">是</span>
            <span :class="{'checkbox': true, 'checked': formData.isoriginal == false}" @click="setFormData('isoriginal', false)">否</span>
        </div>
        <div class="form-item" v-if="formData.isoriginal == false">
            <div class="hint-w">
                <span class="hint require">原作者:</span>
            </div>
            <input type="text" class="author" placeholder="請輸入原作者姓名" v-model="formData.author"/>
        </div>
        <div class="form-item" v-if="formData.isoriginal == false">
            <div class="hint-w">
                <span class="hint">來源網站名称:</span>
            </div>
            <input type="text" class="from-link" placeholder="請輸入文章來源網站名称" v-model="formData.copyfrom"/>
        </div>
        <div class="form-item" v-if="formData.isoriginal == false">
            <div class="hint-w">
                <span class="hint">來源網站网址:</span>
            </div>
            <input type="text" class="from-link-title" placeholder="請輸入文章來源網站网址" v-model="formData.fromurl"/>
        </div>
        <div class="form-item">
            <div class="hint-w">
                <span class="hint require">文章正文:</span>
            </div>
            <vue-editor
                ref="editor" 
                class="content" 
                id="editor"
                placeholder="輸入文章正文"
                :editor-toolbar="customToolbar"
                useCustomImageHandler 
                @image-removed="onImageRemoved" 
                @image-added="onImageAdded" 
                @text-change="onEditorChanged"
                v-model="formData.content"
            />
            <!-- <textarea cols="30" rows="5"  placeholder="輸入文章正文"></textarea> -->
        </div>
        <div class="form-item">
            <div class="hint-w">
                <span class="hint require">關鍵字:</span>
            </div>
            <input type="text" class="keywords" placeholder="輸入文章內容關鍵字，多个以‘,’逗号分隔" v-model="formData.tag"/>
        </div>
        <div class="form-item">
            <div class="hint-w">
                <span class="hint require">診所:</span>
            </div>
            <a-dropdown :trigger="['click']" @visibleChange="onClinicChange">
                <div class="select clinic" :data-value="mClinic.clinicid" v-text="mClinic.clinicname || '請選擇診所'"></div>
                <a-menu slot="overlay">
                    <a-menu-item key="-1" v-if="loadingClinic">
                        <div class="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </a-menu-item>
                    <a-menu-item @click="onClinicItemClick({clinicid: '', clinicname: ''})" v-if="!loadingClinic">
                        <span>請選擇</span>
                    </a-menu-item>
                    <a-menu-divider v-if="!loadingClinic"/>
                    <a-menu-item v-for="(clinic, index) in clinics" :key="index" @click="onClinicItemClick(clinic)">
                        <span v-text="clinic.clinicname"></span>
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
        <div class="btn-submit" @click="addNews"><div class="lds-ring1" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '發佈文章'}}</div>
    </div>
</template>

<script>
    import { VueEditor } from 'vue2-editor'
    import add_news from '@/views/mixins/add_news'
	export default {
        mixins: [
            add_news
        ],
        components: {
            VueEditor,
        },
		data() {
            return {
                customToolbar: [
                    [{'header': [0,1,2,3]}],
                    ['bold', 'italic', 'underline'],
                    [{'align':''},{'align':'center'},{'align':'right'}],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                    [{'background':[]},{'color':[]}],
                    ['image','link'],
                    ['clean'],
                ]

                /**
                    * align:{”,’center’,’right’} 文本对齐方式 
                    * background 背景色 
                    * blockquote 引用 
                    * bold 加粗 
                    * clean 清楚格式 
                    * code 代码 
                    * code-block 代码块 
                    * color 字体颜色 
                    * direction:{”,’rtl’} 方向 
                    * float：{‘center’,’full’,’left’,’right’} 浮动 
                    * formula 公式 
                    * header 标题 
                    * italic 斜体 
                    * image 图片 
                    * indent 缩进 
                    * link 链接 
                    * list :{‘ordered’,’bullet’,’check’} 列表 有序 多选列别 
                    * script :{‘sub’,’super’} 脚本 
                    * strike 作废 
                    * underline 下划线 
                    * video 视频 
                 */
            }
        },
        methods: {
            onCategoryVisible: function(visible) {
                if(visible) {
                    this.loadCategorys()
                }
            },
            onImageAdded: function (file, Editor, cursorLocation, resetUploader) {
                var formData = new FormData()
                formData.append("files", [file])
                this.$store.dispatch('uploadImage', {
                    imgCategory: 6, form: formData
                }).then((rs)=>{
                    // if(rs.code == 200) {
                    //     var imgList = rs.data || []
                    //     //把获取到的图片url 插入到鼠标所在位置 回显图片
                    //     imgList.forEach(img => Editor.insertEmbed(cursorLocation, 'image', img))
                    // } else {
                    //     // this._toast.warning(this, rs.msg)
                    // }
                }).catch(err=>{
                })
            },
            onImageRemoved: function(file, Editor, cursorLocation, resetUploader) {

            },
            onEditorChanged:function(delta, oldDelta, source) {
                // var el = document.createElement('html')
                // el.innerHTML = this.formData.content
            },
            setFormData: function(k, v){
                this.formData[k] = v
            },
            onCategoryItemClick: function(v) {
                this.mCategory = v
                this.setFormData('catid', v.codeid)
                if(v.codeid)
                    this.setFormData('category', v.codeid)
            },
            onClinicChange: function(v) {
                if(v) {
                    this.getClinicsByDoctor()
                } 
            },
            onClinicItemClick: function(v) {
                this.mClinic = v
                this.setFormData('clinicid', v.clinicid)
            },
            
        }
	};
</script>

<style lang="scss" >
    /* 發佈資訊 start */
    .add-news-form {
        display: flex;
        flex-direction: column;
        color: #231F20;
    }

    .add-news-form .form-item {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        padding-left: 15px;
        position: relative;
    }

    .category-box {
        position: absolute;
        left: 107px;
        top: 38px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        width: 230px;
        border: 1px solid #e8e8e8;
        box-shadow: 1px 1px 5px #eee;
        z-index: 999;
        min-height: 88px;
        align-items: center;
        justify-content: center;
    }

    .category-box > .category-list {
        display: flex;
        flex-direction: column;
    }

    .category-box > .category-list .category-item {
        height: 44px;
        line-height: 44px;
        padding-left: 24px;
        border-bottom: 1px solid #e7e7e7;
        background-color: #fff;
        cursor: pointer;
    }

    .category-box > .category-list .category-item:hover {
        background-color: #f7f7f7;
    }


    .add-news-form .form-item:nth-child(1) {
        margin-top: 10px;
    }

    .add-news-form .form-item > .hint {
        color: #636363;
        font-weight: 400;
        font-size: 16px;
        height: 36px;
        line-height: 36px;
    }

    .add-news-form .form-item > .hint-w {
        display: inline-block;
        width: 110px;
        height: 36px;
        line-height: 36px;
        text-align: right;
        font-weight: 400;
        color: #636363;
        font-size: 16px;
        margin-right: 16px;
    }

    .add-news-form .form-item > .hint-w > .hint.require {
        position: relative;
    }

    .add-news-form .form-item > .hint-w > .hint.require::before {
        content: '*';
        position: absolute;
        left: -9px;
        top: -9px;
        color: #ee1b23;
        font-weight: bold;
        font-size: 16px;
    }

    .add-news-form .form-item > textarea,
    .add-news-form .form-item > input {
        width: 454px;
        height: 36px;
        line-height: 36px;
        padding-left: 16px;
        color: #231F20;
        font-size: 16px;
        font-weight: bold;
        box-sizing: border-box;
        border: 1px solid #D6E7EB;
    }

    
    .add-news-form .form-item > textarea::placeholder,
    .add-news-form .form-item > input::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #969696;
    }

    .ql-editor.ql-blank::before {
        color: #969696 !important;
        font-style: inherit !important;
        font-family: 'Microsoft Yahei', sans-serif;

    }

    .add-news-form .form-item > .content {
        flex: 1;
        margin-right: 50px;
        margin-bottom: 52px;
    }

    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
        border: 1px solid #D6E7EB !important;
    }

    .ql-container.ql-snow {
        margin-top: -1px;
    }

    .add-news-form .form-item > .checkbox {
        display: inline-block;
        height: 36px;
        line-height: 36px;
        position: relative;
        padding-left: 24px;
        color: #969696;
        font-size: 16px;
        font-weight: 400;
        margin-left: 16px;
        margin-right: 16px;
        cursor: pointer;
    }

    .add-news-form .form-item > .checkbox::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-image: url(../../../../assets/imgs/pc/user/img_huichange@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .add-news-form .form-item > .checkbox.checked::after {
        background-image: url(../../../../assets/imgs/pc/user/img_change@2x.png);
    }

    .add-news-form .form-item > .select {
        width: 454px;
        height: 36px;
        box-sizing: border-box;
        line-height: 36px;
        padding-left: 16px;
        color: #231F20;
        font-size: 16px;
        font-weight: bold;
        border: 1px solid #D6E7EB;
        cursor: pointer;
        position: relative;
    }

    .add-news-form .form-item > .select[data-value=''] {
        font-size: 16px;
        font-weight: 400;
        color: #969696;
    }

    .add-news-form .form-item > .select.category {
        width: 230px;
    }

    .add-news-form .form-item > .select::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 36px;
        height: 36px;
        border-left: 1px solid #D6E7EB;
    }

    .add-news-form .form-item > .select::before {
        content: '';
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 6px;
        background-image: url(../../../../assets/imgs/pc/user/img_xiala@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .add-news-form .form-item > textarea {
        width: 668px;
        height: 108px;
        padding: 10px 16px;
    }

    .add-news-form .btn-submit {
        width: 188px;
        height: 42px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #36C4D0;
        border-radius: 15px;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin: 10px 0px 20px 212px;
        cursor: pointer;
        user-select: none;
    }

    .add-news-form .btn-submit:active {
        opacity: 0.9;
    }

    /* 發佈資訊 end */

    // category loading start
    .lds-ring {
        display: inline-block;
        width: 48px;
        height: 48px;
        align-self: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        margin: 4px;
        border: 4px solid #36C4D0;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #36C4D0 transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    // category loading end

    /** loading start */
    .lds-ring1 {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring1 div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring1 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring1 div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring1 div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring1 div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring1 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /** loading end */ 

</style>
