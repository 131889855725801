export default {
    mounted: function(){
        this.formData.editorid = this.userInfo.doctorglobaluserid
    },
    data(){
        return {
            userInfo: this.$store.getters.userInfo,
            categorys: [],
            clinics: [],
            loading: false,
            loadingCategory: false,
            loadingClinic: false,
            mCategory: {
                codedesc: '',
                codeid: ''
            },
            mClinic: {
                clinicid: '',
                clinicname: '',
            },
            formData: {
                author: '',
                category: '',
                catid: 0,
                clinicid: 0,
                content: '',
                contenttype: 1, //1.图文，2.视频
                copyfrom: '',
                editorid: null,
                fromurl: '',
                introduce: '',
                isoriginal: null,
                tag: '',
                title: '',
                mediaslist: []
            },
        }
    },
    methods: {
        async loadCategorys() {
            this.loadingCategory = true
            this.categorys = []
            this.$store.dispatch('getNewsCategorys', {})
            .then((rs) => {
                this.categorys = rs || []
                this.loadingCategory = false
            }).catch((rs) => {
                this.loadingCategory = false
            })
        },
        async getClinicsByDoctor() {
            this.loadingClinic = true
            this.clinics = []
            this.$store.dispatch('getClinicsByDoctor', {doctorglobaluserid: this.userInfo.doctorglobaluserid})
            .then((rs) => {
                this.loadingClinic = false
                this.clinics = rs || []
            }).catch((rs) => {
                this.loadingClinic = false
            })
        },
        async addNews() {
            if(this.loading) return
            if(! this.formData.catid) {
                this._toast.warning(this, '請選擇文章分類')
                return
            }
            if(! this.formData.title) {
                this._toast.warning(this, '請輸入文章標題')
                return
            }
            if(! this.formData.introduce) {
                this._toast.warning(this, '請輸入文章摘要')
                return
            }
            if(Object.prototype.toString.call(this.formData.isoriginal) != '[object Boolean]') {
                this._toast.warning(this, '請選擇是否原創')
                return
            }

            if(this.formData.isoriginal == false) {
                if(! this.formData.author) {
                    this._toast.warning(this, '請輸入原作者')
                    return
                }
                if(! this.formData.copyfrom) {
                    this._toast.warning(this, '請輸入來源網站名称')
                    return
                }
                if(! this.formData.fromurl) {
                    this._toast.warning(this, '請輸入來源網站网址')
                    return
                }

                if(! this.formData.fromurl.toLowerCase().startsWith('http')) {
                    this._toast.warning(this, '來源網站网址格式不正確')
                    return
                }
            }

            if(! this.formData.content) {
                this._toast.warning(this, '請輸入文章正文')
                return
            }
            if(! this.formData.tag || ! this.formData.tag.replace(/,|，/g, '')) {
                this._toast.warning(this, '請輸入關鍵字')
                return
            }
            if(! this.formData.clinicid) {
                this._toast.warning(this, '請選擇診所')
                return
            }
            this.loading = true
            try {
                var rs = await this.$store.dispatch('addNews', this.formData)
                window.scrollTo(0, 0)
                setTimeout(() => {
                    this.mCategory = {
                        codedesc: '',
                        codeid: ''
                    }
                    this.mClinic = {
                        clinicid: '',
                        clinicname: '',
                    }
                    this.formData = {
                        author: '',
                        category: '',
                        catid: 0,
                        clinicid: 0,
                        content: '',
                        contenttype: 1, //1.图文，2.视频
                        copyfrom: '',
                        editorid: null,
                        fromurl: '',
                        introduce: '',
                        isoriginal: null,
                        tag: '',
                        title: '',
                        mediaslist: []
                    }
                    if(/Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent)) {
                        this._toast.success(this, '添加資訊成功！')
                    } else {
                        this.$notification.success({
                            message: '提示',
                            description: '添加資訊成功！',
                            duration: null
                        })
                    }
                    this.loading = false
                }, 100)
            } catch (error) {
                this.loading = false
                this._toast.warning(this, error)
            }
        }
    }
}